import React from 'react'
import './contact.css'
import '../about/about.css'
import {FiPhone,FiLinkedin,FiMail} from 'react-icons/fi'

const Contact = () => {
  return (
    <section id='contact'>
      <h5>Neem contact op</h5>
      <h1>Contacteer Mij</h1>
      <div className="about_cards">
            <article className="about_card">
              <FiMail className='about_icon'/>
              <h5>Email</h5>
              <small>aron.buffel@student.howest.be</small><br/>
              <small>aron.buffel@telenet.be</small>
            </article>

            <article className="about_card">
              <FiPhone className='about_icon'/>
              <h5>Telefoon</h5>
              <small>+32 471 62 21 67</small>
            </article>

            <article className="about_card">
              <FiLinkedin className='about_icon'/>
              <h5>LinkedIn</h5>
              <small>linkedin.com/in/buffel-aron</small>
            </article>
          </div>
    </section>
  )
}

export default Contact