import React from 'react'
import CV from '../../assets/Buffel_Aron_cv.pdf'
const Buttons = () => {
  return (
    <div className='buttons'>
        <a href={CV} download className='btn'>Download mijn CV</a>
        <a href='#contact' className='btn-primary'>Contacteer mij</a>
    </div>
  )
}

export default Buttons