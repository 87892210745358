import React from 'react'
import Nav from "../components/nav/Nav";
import  "./blog.css"
import ImageSlider from "../components/imageSlider/ImageSlider";
import Footer from "../components/footer/Footer";


const BlogPage = (posts) => {

    const tripSlides = {
        slidesDag7: [
            {url:"http://localhost:3000/blogImages/HowestGoesUSATripNaarSanFrancisco/Dag7/ScenerySunset1.jpg", title:"San Francisco Henge"},
            {url:"http://localhost:3000/blogImages/HowestGoesUSATripNaarSanFrancisco/Dag7/ScenerySunset2.jpg", title:"San Francisco Henge"},
            {url:"http://localhost:3000/blogImages/HowestGoesUSATripNaarSanFrancisco/Dag7/AlcatrazIsland1.jpg", title:"Alcatraz gevangenis eiland"},
            {url:"http://localhost:3000/blogImages/HowestGoesUSATripNaarSanFrancisco/Dag7/AlcatrazIsland2.jpg", title:"Alcatraz gevangenis eiland"},
            {url:"http://localhost:3000/blogImages/HowestGoesUSATripNaarSanFrancisco/Dag7/AlcatrazMiniature.jpg", title:"Alcatraz miniatuur"},
            {url:"http://localhost:3000/blogImages/HowestGoesUSATripNaarSanFrancisco/Dag7/AlcatrazPrisonEntrance.jpg", title:"Alcatraz gevangenis ingang"},
            {url:"http://localhost:3000/blogImages/HowestGoesUSATripNaarSanFrancisco/Dag7/ChinatownStreet.jpg", title:"Chinatown"},
            {url:"http://localhost:3000/blogImages/HowestGoesUSATripNaarSanFrancisco/Dag7/ChinatownBuilding.jpg", title:"Chinatown"}
        ],
        slidesInleiding: [
            {url:"http://localhost:3000/blogImages/HowestGoesUSATripNaarSanFrancisco/Inleiding/ScenerySunset3.jpg", title:"Skyline van SF met zonsondergang"},
            {url:"http://localhost:3000/blogImages/HowestGoesUSATripNaarSanFrancisco/Inleiding/ScenerySkyline4.jpg", title:"Straat met uitzicht op Oakland Bay Bridge"},
            {url:"http://localhost:3000/blogImages/HowestGoesUSATripNaarSanFrancisco/Inleiding/ScenerySkyline3.jpg", title:"Straat in downtown San Francisco"}
        ],
        slidesDag1: [
            {url:"http://localhost:3000/blogImages/HowestGoesUSATripNaarSanFrancisco/Dag1/GroupPhotoUnionSquare.jpg", title:"Groepsfoto Union Square"},
            {url:"http://localhost:3000/blogImages/HowestGoesUSATripNaarSanFrancisco/Dag1/ScenerySkyline2.jpg", title:"Skyline van San Francisco van ver"},
            {url:"http://localhost:3000/blogImages/HowestGoesUSATripNaarSanFrancisco/Dag1/ScenerySkyline5.jpg", title:"James C. Flood Mansion en de Grace kathedraal"}
        ],
        slidesDag2_1: [
            {url:"http://localhost:3000/blogImages/HowestGoesUSATripNaarSanFrancisco/Dag2/SceneryGoldenGateBridge.jpg", title:"Golden Gate Bridge"},
            {url:"http://localhost:3000/blogImages/HowestGoesUSATripNaarSanFrancisco/Dag2/PaloAltoGroup.jpg", title:"Palo Alto Networks groepsfoto"},
            {url:"http://localhost:3000/blogImages/HowestGoesUSATripNaarSanFrancisco/Dag2/PaloAltoOffice1.jpg", title:"Palo Alto Networks campus"},
            {url:"http://localhost:3000/blogImages/HowestGoesUSATripNaarSanFrancisco/Dag2/PaloAltoOffice2.png", title:"Palo Alto Networks Building 1"}
        ],
        slidesDag2_2: [
            {url:"http://localhost:3000/blogImages/HowestGoesUSATripNaarSanFrancisco/Dag2/MicrosoftGroup.jpg", title:"Microsoft groepsfoto"},
            {url:"http://localhost:3000/blogImages/HowestGoesUSATripNaarSanFrancisco/Dag2/MicrosoftOffice.jpg", title:"Microsoft campus"},
            {url:"http://localhost:3000/blogImages/HowestGoesUSATripNaarSanFrancisco/Dag2/MicrosoftMeetingRoom.jpg", title:"Microsoft meeting room"},
            {url:"http://localhost:3000/blogImages/HowestGoesUSATripNaarSanFrancisco/Dag2/MicrosoftSign.jpg", title:"Microsoft logo"},
            {url:"http://localhost:3000/blogImages/HowestGoesUSATripNaarSanFrancisco/Dag2/AppleVisitor.jpeg", title:"Apple Visitor Center"},
            {url:"http://localhost:3000/blogImages/HowestGoesUSATripNaarSanFrancisco/Dag2/AppleOffice.jpeg", title:"Apple campus"},
            {url:"http://localhost:3000/blogImages/HowestGoesUSATripNaarSanFrancisco/Dag2/AppleVisitorAugmentedReality1.jpeg", title:"Apple miniatuur campus met AR"},
            {url:"http://localhost:3000/blogImages/HowestGoesUSATripNaarSanFrancisco/Dag2/AppleVisitorAugmentedReality2.gif", title:"Apple miniatuur campus met AR"}
        ],
        slidesDag3_1: [
            {url:"http://localhost:3000/blogImages/HowestGoesUSATripNaarSanFrancisco/Dag3/SceneryPaintedLadies.jpg", title:"Painted Ladies"},
            {url:"http://localhost:3000/blogImages/HowestGoesUSATripNaarSanFrancisco/Dag3/ScenerySkyline1.jpg", title:"San Francisco's skyline"}
        ],
        slidesDag3_2: [
            {url:"http://localhost:3000/blogImages/HowestGoesUSATripNaarSanFrancisco/Dag3/Berkeley1.jpg", title:"California University at Berkeley"},
            {url:"http://localhost:3000/blogImages/HowestGoesUSATripNaarSanFrancisco/Dag3/Berkeley2.jpg", title:"California University at Berkeley"},
            {url:"http://localhost:3000/blogImages/HowestGoesUSATripNaarSanFrancisco/Dag3/Berkeley3.jpg", title:"California University at Berkeley"},
            {url:"http://localhost:3000/blogImages/HowestGoesUSATripNaarSanFrancisco/Dag3/Berkeley4.jpg", title:"California University at Berkeley"},
            {url:"http://localhost:3000/blogImages/HowestGoesUSATripNaarSanFrancisco/Dag3/Berkeley5.jpg", title:"California University at Berkeley"}

        ],
        slidesDag4_1: [
            {url:"http://localhost:3000/blogImages/HowestGoesUSATripNaarSanFrancisco/Dag4/ScenerySkyline2.jpg", title:"Skyline van San Francisco van ver"},
            {url:"http://localhost:3000/blogImages/HowestGoesUSATripNaarSanFrancisco/Dag4/SceneryPaintedLadies.jpg", title:"Painted Ladies"},
            {url:"http://localhost:3000/blogImages/HowestGoesUSATripNaarSanFrancisco/Dag4/OdooOffice1.jpg", title:"Odoo kantoor"},
            {url:"http://localhost:3000/blogImages/HowestGoesUSATripNaarSanFrancisco/Dag4/OdooOffice2.jpg", title:"Odoo kantoor"},
            {url:"http://localhost:3000/blogImages/HowestGoesUSATripNaarSanFrancisco/Dag4/OdooSign.jpg", title:"Odoo logo"}
        ],
        slidesDag4_2: [
            {url:"http://localhost:3000/blogImages/HowestGoesUSATripNaarSanFrancisco/Dag4/GroupPhotoOracle.jpg", title:"Groepsfoto Oracle"},
            {url:"http://localhost:3000/blogImages/HowestGoesUSATripNaarSanFrancisco/Dag4/OracleOffice1.jpg", title:"Oracle kantoor"},
            {url:"http://localhost:3000/blogImages/HowestGoesUSATripNaarSanFrancisco/Dag4/OracleOffice2.jpg", title:"Oracle kantoor"}
        ],
        slidesDag5: [
            {url:"http://localhost:3000/blogImages/HowestGoesUSATripNaarSanFrancisco/Dag5/CloudFlareLavalamps.jpg", title:"CloudFlare lavalampen"},
            {url:"http://localhost:3000/blogImages/HowestGoesUSATripNaarSanFrancisco/Dag5/CloudFlareOffice1.jpg", title:"CloudFlare kantoor"},
            {url:"http://localhost:3000/blogImages/HowestGoesUSATripNaarSanFrancisco/Dag5/CloudFlareOffice2.jpg", title:"CloudFlare kantoor"},
            {url:"http://localhost:3000/blogImages/HowestGoesUSATripNaarSanFrancisco/Dag5/ScenerySkyline6.jpg", title:"Salesforce Park"}
        ],
        slidesDag6: [
            {url:"http://localhost:3000/blogImages/HowestGoesUSATripNaarSanFrancisco/Dag6/BaseballOraclePark.jpg", title:"Oracle Park"},
            {url:"http://localhost:3000/blogImages/HowestGoesUSATripNaarSanFrancisco/Dag6/BaseballPlayers.jpg", title:"Giants spelers"},
            {url:"http://localhost:3000/blogImages/HowestGoesUSATripNaarSanFrancisco/Dag6/BaseballOracleParkVideo.mp4", title:"Baseball"}
        ]

    }


        return (
            <>
            <section id='blogPage'>
            <Nav/>
            <div className="titleHeader">
                <a onClick={() => { window.history.back() }} className="backIcon"><span className="backArrow2">❮</span><span className="backArrow1">❮</span>❮</a>
                <h3>Aron Buffel's blog</h3>
            </div>
            <div className="blogPost">
                <div className="titleAndDate">
                    <h4>Howest Goes USA trip naar San Francisco</h4>
                    <h5>3 april 2023 - 11 april 2023</h5>
                </div>

                <div className="alinea alinea-1">
                    <div className="blogText">
                        <h3>Inleiding</h3>
                        <p>Dit jaar organiseerde Howest opnieuw de trip naar San Francisco in de USA, na een 3 jarige stop wegens corona. Het verheugde me enorm om te vernemen dat de reis dit jaar weer zou plaatsvinden. Toen we dit hoorden hadden een paar vrienden (Jonathan Depaepe en Yarne Savaete) en ik ons meteen ingeschreven. We waren ook erg opgelucht om het nieuws te horen dat we bij de groep zaten die mee mochten.
                        </p>
                    </div>
                    <div className="blogPicture">
                        <div className="imageSlider">
                            <ImageSlider slides={tripSlides.slidesInleiding}/>
                        </div>
                    </div>
                </div>

                <div className="alinea alinea-2">
                    <div className="blogPicture">
                        <div className="imageSlider">
                            <ImageSlider slides={tripSlides.slidesDag1}/>
                        </div>
                    </div>
                    <div className="blogText">
                        <h3>Dag 1</h3>
                        <p>Het startte smorgensvroeg in Brugge op maandag 2 april, met het verzamelen van de Howest
                            studenten uit Brugge. Toen reed de bus door naar Kortrijk om daar de andere Howest studenten
                            uit Kortrijk op te halen en de studenten van Devine. Toen begon de reis echt met eerst een
                            6 uur lange busrit naar de luchthaven van Parijs. Waar we toen onze 12 uur lange vlucht
                            moesten nemen naar San Francisco. Toen we na 12 uur landde in San Francisco was het nog
                            steeds ochtend in de zelfde dag terwijl er vele uren gepasseerd waren, toch wel raar die
                            jetlag. </p>
                        <p>Het eerste wat we deden in San Francisco is een rit naar onze hostel, waar we onze bagage al
                            konden plaatsen voor dat we begonnen aan stadswandeling om de buurt wat te verkennen. In
                            deze tour hadden we voor het eerst de typische “cable cars” gebruikt. Dit is wel iets heel
                            apart, het lijkt mij ook wel nog een moeilijke job voor de bestuurders. We liepen ook door
                            typisch San Francisco-plaatsen met steile hellingen en mooie uitzichten. De wandeling was
                            uiteindelijk naar een van de vele pieren, “Pier 39” dit is een pier met veel restaurants en
                            entertainment. Naast de pier is ook een plaats waar zeeleeuwen zicht verzamelen.</p>
                    </div>
                </div>

                <div className="alinea alinea-3">
                    <div className="blogText">
                        <h3>Dag 2</h3>
                        <p>De tweede dag startte met een wandeling over de Golden Gate Bridge. Op de brug hadden we
                            echt mooie zichten van de stad met de skyline van alle skyscrapers. Na we de brug over
                            hadden gestoken zaten we weer op de bus richting Silicon Valley voor ons eerste
                            bedrijfsbezoek naar Palo Alto Networks. Hier kregen we eerst een briefing over het bedrijf,
                            hier leerden we dat Palo Alto een netwerk bedrijf is waar security vooral centraal staat.
                            Hier kregen ook nog een paar medewerkers te horen, ze deelden met ons wat over hun werk
                            ervaring en ook wat tips. Deze tips waren wel erg informatief omdat een deel ervan ging over
                            het zoeken en solliciteren voor jobs en ook andere algemene tips dat handig zijn voor mensen
                            in IT. Toen kregen we ook een rondleiding van de campus, dit was een erg grote campus met
                            vele gebouwen en verschillende accommodaties zoals vele eetkraampjes, een fitness en yoga en
                            sport lessen.</p>
                        <p>Na het eerste bedrijfsbezoek waren we even vrij om te eten. Toen hebben ik voor het eerst
                            In-And-Out kunnen eten, ik had dit altijd al wel eens willen eten wat dit zag er als de
                            beste burgers uit op sociale media. En het was totaal geen teleurstelling, de burgers waren
                            zeer goed. </p>
                    </div>
                    <div className="blogPicture">
                        <div className="imageSlider">
                            <ImageSlider slides={tripSlides.slidesDag2_1}/>
                        </div>
                    </div>
                </div>

                <div className="alinea alinea-4">
                    <div className="blogPicture">
                        <div className="imageSlider">
                            <ImageSlider slides={tripSlides.slidesDag2_2}/>
                        </div>
                    </div>
                    <div className="blogText">
                        <p>Na de lunch zaten we weer op de bus naar ons tweede bedrijfsbezoek deze keer was dit
                            Microsoft. In deze briefing dat we kregen ging het vooral over AI en low code. Hier waren
                            ook veel vragen uit de groep, hierop hebben we veel nuttige antwoorden en tips gekregen over
                            het bedrijfsleven later en de toekomst van IT. De vragenronde was zelf zo lang uitgelopen
                            dat er geen tijd meer was voor een tour van de campus.</p>
                        <p>Na het bezoek van Microsoft waren we onderweg naar het Apple Visitor Center waar we een tour
                            kregen met wat info over het Apple en hun campus. In de Visitor Center is er ook een
                            miniatuur van de Apple campus waarmee je dan met een iPad kan op inzoomen in Augmented
                            Reality.</p>
                    </div>
                </div>

                <div className="alinea alinea-5">
                    <div className="blogText">
                        <h3>Dag 3</h3>
                        <p>De derde dag startte met een Alumni event waar enkele Belgen die verhuist zijn naar Amerika
                            hun ervaringen en tips deelden. De eerste spreker was Thomas, hij is een van de Belgen die
                            verhuist zijn naar de Verengde Staten om te werken in Silicon Valley voor OpenAI. Hij
                            vertelde zijn verhaal hoe dit allemaal gebeurd is en enkele tips die hij ons meegaf, die
                            hij zelf heeft ondervonden in zijn carrière.</p>
                        <p>De andere spreker die kwam is Christean, hij is ex Pinterest medewerker en werkt nu voor een
                            nieuwe startup genaamd Cambly. Hij deed ook zijn verhaal over zijn carrière en gaf ook wat
                            tips. Deze tips van beide waren wel erg interessant ze gingen voornamelijk over hoe je het
                            beste kan maken van je carrière, zoals dat een netwerk bouwen erg belangrijk is en dat zelf
                            geïnteresseerd zijn buiten het vak ook vele voordelen bied. </p>
                    </div>
                    <div className="blogPicture">
                        <div className="imageSlider">
                            <ImageSlider slides={tripSlides.slidesDag3_1}/>
                        </div>
                    </div>
                </div>

                <div className="alinea alinea-6">
                    <div className="blogPicture">
                        <div className="imageSlider">
                            <ImageSlider slides={tripSlides.slidesDag3_2}/>
                        </div>
                    </div>
                    <div className="blogText">
                        <p>Achter de lunchpauze pakten we de BART naar Berkeley om de universiteit te bezoeken,
                            de BART is de naam van de metro lijnen is San Francisco. Op de universiteit kregen we
                            een rondleiding van enkele van de studenten die hier studeren. Dit was een erg grote campus
                            met vele grote gebouwen voor specifieke doelen zoals een gebouw voor de bibliotheek, een
                            voor “physiology”, een voor “engineering” en nog vele meer. </p>
                        <p>Dit was een erg mooie campus maar voor het prijskaartje van enkele tienduizenden dollar per
                            jaar zou ik het er persoonlijk niet voor overhebben. Toch wel blij dat het Belgische
                            onderwijs niet zo prijzig is.</p>
                    </div>
                </div>

                <div className="alinea alinea-7">
                    <div className="blogText">
                        <h3>Dag 4</h3>
                        <p>We startte de vierde dag met een wandeling lang de Painted Ladies en dan nog een stop op de
                            Twin Peaks waarop je een mooie panorama zicht hebt over de hele stad. Hier waren we ook nog
                            even vrij om rond te lopen. </p>
                        <p>Hierna namen we terug de bus naar Silicon Valley, deze keer om Odoo te bezoeken. Dit is een
                            ERP systeem dat erg is geëvolueerd over de tijd. Het was oorspronkelijk een klein programma
                            maar is uit gegroeid tot een erg groot programma met veel integraties. Het is ook een
                            Belgisch bedrijf dat is gestart in Waterschei. Ook hier hebben we vele tips gekregen die
                            we later zullen kunnen gebruiken.</p>
                    </div>
                    <div className="blogPicture">
                        <div className="imageSlider">
                            <ImageSlider slides={tripSlides.slidesDag4_1}/>
                        </div>
                    </div>
                </div>

                <div className="alinea alinea-8">
                    <div className="blogPicture">
                        <div className="imageSlider">
                            <ImageSlider slides={tripSlides.slidesDag4_2}/>
                        </div>
                    </div>
                    <div className="blogText">
                        <p>Na de middag deden we nog een bedrijfsbezoek aan Oracle. Dit was een erg mooie campus met
                            veel gebouwen en ook een van de sportzeilboten op display. Hier kregen we een voorstelling
                            over Oracle en hun nieuwe low code software, waarmee je gemakkelijk web apps kan maken
                            zonder te programmeren. </p>
                        <p>Hierna deden we nog een wandeling door de Castro wijk en door Mission Dolores Park.
                            Dit is altijd leuk om nog wat van de voor ons nieuwe stad te verkennen.</p>
                    </div>
                </div>

                <div className="alinea alinea-9">
                    <div className="blogText">
                        <h3>Dag 5</h3>
                        <p>De vijfde dag startte met een wandeling door de stad en een bezoek aan het Salesforce Park.
                            Daarna liep de wandeling ook langs het Oracle Park Stadium.</p>
                        <p>Hier in de buurt was ook ons volgende bedrijfsbezoek aan CloudFlare. Wat erg interessant is
                            hun manier om randomness te bepalen aan de hand van vele lavalampen. Hier kregen we ook een
                            office tour en ook een voorstelling over CloudFlare en hun services. Dit was ook erg
                            interessant en ook weer vele tips kunnen halen uit mensen met veel ervarging. </p>
                    </div>
                    <div className="blogPicture">
                        <div className="imageSlider">
                            <ImageSlider slides={tripSlides.slidesDag5}/>
                        </div>
                    </div>
                </div>
                <div className="alinea alinea-10">
                    <div className="blogPicture">
                        <div className="imageSlider">
                            <ImageSlider slides={tripSlides.slidesDag6}/>
                        </div>
                    </div>
                    <div className="blogText">
                        <h3>Dag 6</h3>
                        <p>De zesde dag waren we vrij en hadden we besloten om een baseball game te gaan bekijken.
                            Deze match was de San Francisco Giants versus de Kansas City Royals. Dit was wel een erg
                            leuke ervaring die ik waarschijnlijk niet meer zal meemaken hier in Europa. Het is toch wel
                            iets anders dan voetbal. </p>
                    </div>
                </div>

                <div className="alinea alinea-11">
                    <div className="blogText">
                        <h3>Dag 7</h3>
                        <p>De zevende dag startte vroeg in de morgen met een ochtendwandeling met mezelf, het was namens
                            net de dag dat het San Francisco henge is. Dit is wanneer het pad van de zon op de zelfde
                            lijn loopt als de straten van het grid van San Francisco. Dit zorgde voor een paar mooie fotos.</p>
                        <p>Ook op deze dag deden we een bezoek aan Alatraz een oude streng bewaakte gevangenis uit de
                            Verenigde Staten. Het is namelijk op een eiland gelegen zodanig de gevangenen goed vast
                            zitten. De gevangenis was erg groot met erg kleine cellen voor de gevangenen. We waren vrij
                            op het eiland om rond te lopen en verkennen. In het gevangenis gebouw was er ook een audiotour. </p>
                    </div>
                    <div className="blogPicture">
                        <div className="imageSlider">
                            <ImageSlider slides={tripSlides.slidesDag7}/>
                        </div>
                    </div>
                </div>

            </div>

            </section>
                <Footer/></>
          );
    }
export default BlogPage;