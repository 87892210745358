import React from 'react'
import './experience.css'
import {FaLaravel} from 'react-icons/fa'
import {SiDotnet,SiNodedotjs,SiPython,SiJavascript,SiJava,SiHtml5,SiCss3,SiReact} from 'react-icons/si'


const Experience = () => {
  return (
    <section id='experience'>
      <h5>Welke skills heb ik</h5>
      <h1>Mijn Ervaring</h1>

      <div className="experience_container">

        <div className='experience_front'>
          <h3>Frontend Development</h3>

          <div className='experience_content'>
            <article className='experience_details'>
              <SiHtml5 className='experience_details-icon'/>
              <div>
              <h4>HTML</h4>
              <small>Experienced</small>
              </div>
            </article>
          
            <article className='experience_details'>
              <SiCss3 className='experience_details-icon'/>
              <div>
              <h4>CSS</h4>
              <small>Experienced</small>
              </div>
            </article>
          
            <article className='experience_details'>
              <SiJavascript className='experience_details-icon'/>
              <div>
              <h4>Javascript</h4>
              <small>Experienced</small>
              </div>
            </article>
          
            <article className='experience_details'>
              <FaLaravel className='experience_details-icon'/>
              <div>
              <h4>Laravel</h4>
              <small>Experienced</small>
              </div>
            </article>
          
            <article className='experience_details'>
              <SiReact className='experience_details-icon'/>
              <div>
              <h4>React</h4>
              <small>Experienced</small>
              </div>
            </article>
          </div>
        </div>

        <div className='experience_back'>
          <h3>Backend Development</h3>

          <div className='experience_content'>
            <article className='experience_details'>
              <SiJava className='experience_details-icon'/>
              <div>
              <h4>Java</h4>
              <small>Experienced</small>
              </div>
            </article>
          
            <article className='experience_details'>
              <SiPython className='experience_details-icon'/>
              <div>
              <h4>Python</h4>
              <small>Experienced</small>
              </div>
            </article>
          
            <article className='experience_details'>
              <SiNodedotjs className='experience_details-icon'/>
              <div>
              <h4>NodeJS</h4>
              <small>Experienced</small>
              </div>
            </article>
          
            <article className='experience_details'>
              <SiDotnet className='experience_details-icon'/>
              <div>
              <h4>.NET</h4>
              <small>Experienced</small>
              </div>
            </article>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Experience