
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { createRoot } from 'react-dom/client';

import './index.css'
import Home from "./pages/Home";
import BlogPage from "./pages/BlogPage";
import NotFound from "./pages/NotFound";
import React from 'react'
import Icon from "./pages/Icon";


function App() {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/blog" element={<BlogPage posts="a" />} />
                <Route path="/blog/1" element={<BlogPage posts="a" />} />
                <Route path="/icon" element={<Icon/>} />
                <Route path='*' element={<NotFound/>}/>
                <Route path="/" element={<Home />} />
            </Routes>
        </BrowserRouter>
    );
}

const rootElement = document.getElementById('root');
const root = createRoot(rootElement);

// Render your app inside the root
root.render(<App />);

const posts = [
    {
        id: 1,
        name: 'Os Tibiale Externum',
        category: 'feet',
        descriptionShort: 'Een extra voetbotje aan de binnenzijde van je voetboog.',
        description: 'Een extra voetbotje aan de binnenzijde van je voetboog. Dat is kort samengevat een os ' +
            'tibiale externum. Het extra voetbotje kan pijn veroorzaken, vooral als je te smalle schoenen ' +
            'draagt of je tibialis posteriorpees rekt.',
        causesText: 'Een os tibiale externum is een aangeboren voetafwijking. De diagnose wordt gesteld via een RX-foto.',
        causesList: [],
        treatmentText: 'Os Tibiale Externum kan behandeld worden door:',
        treatmentList: [{treatmentListItem: 'podologische zolen'},{treatmentListItem: 'infiltratie'},
            {treatmentListItem: 'gips'},{treatmentListItem: 'een operatie'}]
    }
]