import React from 'react'
import './about.css'
import aboutMePicture from '../../assets/aboutMePicture.jpg'
import {FaUserGraduate} from 'react-icons/fa'
const About = () => {
  return (
    <section id='about'>
      <h5>Kom meer te weten</h5>
      <h1>Over Mij</h1>

      <div className="about_container">
        <div className="about_me">
          <div className="about_me-image">
            <img src={aboutMePicture} alt="about image"/>
          </div>
        </div>

        <div className="about_content">
          <div className="about_cards">
            <article className="about_card">
              <FaUserGraduate className='about_icon'/>
              <h5>Education</h5>
              <small>Bachlor in TI: Software Engeneering at HOWEST Bruges</small>
            </article>

            <article className="about_card">
              <FaUserGraduate className='about_icon'/>
              <h5>Education</h5>
              <small>Bachlor in TI: Software Engeneering</small>
            </article>

            <article className="about_card">
              <FaUserGraduate className='about_icon'/>
              <h5>Education</h5>
              <small>Bachlor in TI: Software Engeneering</small>
            </article>
          </div>
          <p>
              Lorem, ipsum dolor sit amet consectetur adipisicing elit. Illo consequuntur, praesentium placeat, voluptatibus ut mollitia tempora eos officiis veritatis voluptatem sit nesciunt! Earum, commodi at repellat temporibus dignissimos repellendus magnam! Lorem ipsum dolor sit, amet consectetur adipisicing elit. Suscipit quam dolore ad provident eaque culpa ut, modi commodi aut exercitationem aspernatur doloremque quasi amet nemo laboriosam ab deleniti vitae fugit. Lorem, ipsum dolor sit amet consectetur adipisicing elit. Officia excepturi alias voluptatum deleniti nesciunt non repellendus laboriosam quod! Nesciunt reiciendis similique repellendus laboriosam velit eius est necessitatibus pariatur fugit sit!
            </p>
          <a href='#contact' className='btn-primary'>Let's Talk</a>
        </div>
      </div>

    </section>
  )
}

export default About

