import React from 'react'
import './portfolio.css'
import IMG1 from '../../assets/blogImages/1668549027683.jpg'

const Portfolio = () => {
  return (
    <section id='portfolio'>
      <h5>Mjn IT ervaringen</h5>
      <h1>Blog</h1>
      
      <div className="portfolio_container">
        <article className="portfolio_item">
          <div className='portfolio_item-image'>
            <div>
              <img src={IMG1} alt=""></img>
            </div>
          </div>
          <div className='portfolio_item-overlay'>
            <h4 className="blogArticleCategory">Hackaton</h4>
            <h3 className="blogArticleTitle">Hack The Future 2022</h3>
            <h5 className="blogArticleDate">15 november 2022</h5>
            <div className='portfolio_item-cta'>
              <a href='/blog/1' className='btn'>Lees meer</a>
            </div>
          </div>
        </article>



        <article className="portfolio_item">
          <div className='portfolio_item-image'>
            <div>
              <img src={IMG1} alt=""></img>
            </div>
          </div>
          <div className='portfolio_item-overlay'>
            <h4 className="blogArticleCategory">Hackaton</h4>
            <h3 className="blogArticleTitle">Hack The Future 2022</h3>
            <h5 className="blogArticleDate">15 november 2022</h5>
            <div className='portfolio_item-cta'>
              <a href='/blog/1' className='btn'>Lees meer</a>
            </div>
          </div>
        </article>



        <article className="portfolio_item">
          <div className='portfolio_item-image'>
            <div>
              <img src={IMG1} alt=""></img>
            </div>
          </div>
          <div className='portfolio_item-overlay'>
            <h4 className="blogArticleCategory">Hackaton</h4>
            <h3 className="blogArticleTitle">Hack The Future 2022</h3>
            <h5 className="blogArticleDate">15 november 2022</h5>
            <div className='portfolio_item-cta'>
              <a href='/blog/1' className='btn'>Lees meer</a>
            </div>
          </div>
        </article>
      

      
        <article className="portfolio_item">
          <div className='portfolio_item-image'>
            <div>
              <img src={IMG1} alt=""></img>
            </div>
          </div>
          <div className='portfolio_item-overlay'>
            <h4 className="blogArticleCategory">Hackaton</h4>
            <h3 className="blogArticleTitle">Hack The Future 2022</h3>
            <h5 className="blogArticleDate">15 november 2022</h5>
            <div className='portfolio_item-cta'>
              <a href='/blog/1' className='btn'>Lees meer</a>
            </div>
          </div>
        </article>
      


      </div>
    </section>
  )
}

export default Portfolio